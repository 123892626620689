import {get,post,deleteReq} from '../util/request.js';
import {showErrorTip,showSuccessTip} from '../util/index.js';
// 删除
const deleteProduct = async (data) => {
  const res = await deleteReq({
    url:'/api/ScratchProjectAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }else{
    showSuccessTip('已删除')
  }
  return res;
};
// 取消发布
const cancelShare = async (data) => {
  const res = await deleteReq({
    url:'/api/ShareScratchProjectAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }else{
    showSuccessTip('提交成功')
  }
  return res;
};
// 获取独立原创作品列表
const getOriginalList = async (data) => {
  const res = await get({
    url:'/api/ScratchProjectAPI',
    data
  });
  if(res.error){
    if(res.error !== 'login-required'){
      showErrorTip(res.data);
    }
  }
  return res;
}
// 获取作者信息
const getUser = async (data) => {
  const res = await get({
    url:'/api/UserProfileAPI',
    data
  });
  if(res.error){
    if(res.error !== 'login-required'){
      showErrorTip(res.data);
    }
  }
  return res;
}
// 获取独收藏作品列表
const getFavoriteList = async (data) => {
  const res = await get({
    url:'/api/FavoriteProjectAPI',
    data
  });
  if(res.error){
    if(res.error !== 'login-required'){
      showErrorTip(res.data);
    }
  }
  return res;
}

// 取消收藏
const cancelFavorite = async (data) => {
  const res = await post({
    url:'/api/FavoriteProjectAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}
 
export {
  getOriginalList,
  getFavoriteList,
  cancelShare,
  deleteProduct,
  cancelFavorite,
  getUser
}