
import {Button,MessageBox} from 'element-ui';
export default {
  name:'userhead',
  props:{
    data:{},
    type:'',
    uid:''
  },
  components:{
    elButton:Button
  },
  data(){
    return {
    }
  },
  methods:{
    handleClickBtn(type,id){
      // 0 查看
      if(type === 0){
        this.$router.push(`/product-detail?id=${id}`);
        // window.open(`./scratch3/player.html?id=${id}`);
      }
      // 1 发布
      if(type === 1){
        this.$router.push(`/publish?id=${id}`);
        return ;
      }
      // 2 撤销发布
      if(type === 2){
        this.$emit('cancelShare',id);
      }
      // 3 删除
      if(type === 3){
        MessageBox.confirm('确认删除？','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.$emit('delete',id);
        }).catch(()=>{

        })
      }
      // 4 修改
      if(type === 4){
        window.open(`./scratch3/index.html?id=${id}`);
      }
      // 5 取消收藏
      if(type === 5){
        this.$emit('cancelFavorite',id);
      }
    }
  }
}