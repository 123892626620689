import  {Tabs,TabPane,Pagination} from 'element-ui';
import UserHead from './components/head/index.vue';
import Product from './components/product/index.vue';
import {getOriginalList,getFavoriteList ,getUser,cancelShare,deleteProduct,cancelFavorite} from '../api/user.js';
import {showErrorTip} from '../util/index.js';
export default {
  name:'user',
  components:{
    UserHead,
    Product,
    elTabs:Tabs,
    elTabPane:TabPane,
    elPagination:Pagination
  },
  data(){
    this.limit = 20;
    this.uid = '';
    return {
      currentPage:1,
      collectCurrentPage:1,
      activeTab:'orginal',
      userInfo:{},
      originalList:[],
      orginalTotal:0,
      collectList:[],
      collectTotal:0
    }
  },
  async mounted(){
    this.uid = this.$route.query.uid||'';
    this.getOriginalList();
    this.getUserInfo();
  },
  methods: {
    async getUserInfo(){
      const res = await getUser({uid:this.uid});
      if(res.error === null){
        this.userInfo = res.data;
      }
    },
    async getOriginalList(){
      const res = await getOriginalList({
        limit:this.limit,
        offset:this.currentPage - 1,
        uid:this.uid
      });
      if(res.error === null){
        const { results = [],total=0} = res.data;
        this.originalList = results;
        this.orginalTotal = total
      }else{
        if(res.error!== 'login-required'){
          showErrorTip(res.data);
        }
      }
    },
    async getCollectList(){
      const res = await getFavoriteList({
        limit:this.limit,
        offset:this.collectCurrentPage -1 ,
        uid:this.uid
      });
      if(res.error === null){
        const { results = [],total=0} = res.data;
        this.collectList = results;
        this.collectTotal = total;
      }else{
        if(res.error!== 'login-required'){
          showErrorTip(res.data);
        }
      }
    },
    handleClick(tab) {
      const {name} = tab;
      this.activeTab = name;
      if(name === 'collect'){
        this.getCollectList();
        return;
      }
      if(name === 'orginal'){
        this.getOriginalList();
      }
    },
    changePage(curPage){
      this.currentPage = curPage;
      this.getOriginalList();
    },
    collectChangePage(curPage){
      this.collectCurrentPage = curPage;
      this.getCollectList();
    },
    async cancelShare(id){
     const res = await cancelShare({id});
     if(res.error === null){
       this.getOriginalList();
     } 
    },
    async cancelFavorite(id){
     const res = await cancelFavorite({id,action:false});
     if(res.error === null){
       this.getCollectList();
     } 
    },
    async deleteProduct(id){
     const res = await deleteProduct({id});
     if(res.error === null){
       this.getOriginalList();
     } 
    },
  }
}